import { USER_DATA, SET_CURRENT_PARENT } from "./actionTypes";
let data = JSON.parse(localStorage.getItem("access_token_jeep_userData"));

const initialState = {
  userData: data,
  fileManager: {
    currentParent: null,
  },
};

const customReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case USER_DATA:
      return {
        ...state,
        userData: data,
      };
    case SET_CURRENT_PARENT:
      return {
        ...state,
        fileManager: {
          currentParent: data,
        },
      };
    default:
      return state;
  }
};

export default customReducer;
