import styled from "styled-components";
import bg from "../assets/login_bg_1.png";
import bg2 from "../assets/login_bg2.png";
import bg3 from "../assets/login_bg_3.png";

export const PrivateContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: "#ffffff";
  /* @media (max-width: 769px) {
    flex-direction: column;
  }

  @media screen and (max-width: 480px) {
  } */
`;
export const PrivateInnerContainer = styled.div`
  height: 100%;
  width: 100%;
  /* min-width: 1440px; */
  display: flex;
  justify-content: center;
  /* background-color: aqua; */
  /* @media (max-width: 1445px) {
    min-width: 100%;
    justify-content: ${(props) =>
    props.sidebarToggle ? "center" : "space-between"};
  } */
`;
export const PrivateFirstContainer = styled.div`
  height: 100%;
  flex: 0 0 300px;
  /* flex: ${(props) => (props.sidebarToggle ? "0 0 85px" : "0 0 230px")}; */
  /* min-width: 230px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid black;
  /* @media (max-width: 1423px) {
    /* flex: 0; */
  /* width: 20%; */
  /* @media (max-width: 1031px) {
    width: 22%;
  } */
  /* @media (max-width: 769px) {
    flex: 0;
    width: auto;
  } */
`;
export const PrivateSecondContainer = styled.div`
  height: 100%;
  width: calc(100% - 300px);
  /* width: 85%; */
  /* max-width: 1140px; */
  /* min-width: 1140px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* overflow-y: hidden !important; */
  background-color: #ffffff;
  border: 1px solid black;

  /* @media (max-width: 1423px) {
    min-width: auto;
    max-width: 100%;
    width: 80%;
  } */
  /* @media (max-width: 1031px) {
    min-width: 80%;
  } */
  /* @media (max-width: 769px) {
    min-width: 100%;
  } */
`;

export const ParentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const InnerContainer = styled.div`
  width: 100%;
  display: flex;
`;
export const InnerPrivateContainer = styled.div`
  /* height: 100%; */
  width: 100%;
  height: calc(100vh - 80px);
  /* display: flex;
  flex-direction: column; */
  /* justify-content: flex-start;
  align-items: flex-start; */
  padding: 1.5rem 2rem;
  overflow-y: auto;
  background-color: rgb(16, 16, 16);
  /* background-color: rgb(247, 247, 247); */
  /* @media (max-width: 510px) {
    padding: 0 0.7rem;
  } */
`;
export const SectionBox = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const LoginContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-end;
  color: white;
  position: relative;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;
export const BgLogo = styled.div`
  width: 420px;
  height: 250px;
  /* border: 1px solid white; */
  position: absolute;
  bottom: 100px;
  left: 35vw;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;
export const LoginBoxContainer = styled.div`
  border: 1px solid white;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const LoginBox = styled.div`
  width: 380px;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 1.5rem 2rem;
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 80px;
  top: 120px;
  gap: 15px;
  border-radius: 5px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
`;
export const LoginButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-weight: 700;
  font-size: 1rem;
  height: 45px;
  background-color: rgb(255, 186, 0);
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
`;
export const FormLogoSection = styled.div`
  /* border: 1px solid white; */
`;
export const IconBox = styled.div`
  width: ${({ width }) => (width ? width : "30px")};
  height: ${({ height }) => (height ? height : "30px")};
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
export const FormHeadingSection = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Heading = styled.div`
  font-size: 2rem;
  font-weight: bold;
`;
export const SubContent = styled.div`
  font-size: 0.85rem;
`;
export const InputFieldBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const LoginCheckbox = styled.div`
  display: flex;
  gap: 5px;
`;
// Styled span for the custom checkmark
export const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: transparent;
  border: 1px solid rgba(144, 144, 144, 1);
  border-radius: 5px;

  // After the checkbox is checked

  // Create the checkmark/indicator (hidden when not checked)
  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`;
export const CheckboxLabel = styled.label`
  height: 20px;
  /* border:1px solid white; */
  font-size: 0.8rem;
  /* display: inline-block; */
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-right: 15px;
  cursor: pointer;

  user-select: none;
  color: #fff;

  &:hover .checkmark {
    background-color: rgb(255, 186, 0);
  }
`;

// Styled input for the checkbox
export const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  // Show the checkmark when checked
  &:checked ~ ${Checkmark} {
    background-color: rgb(255, 186, 0);
    /* border-color: #2196F3; */
  }

  // Style the checkmark/indicator
  &:checked ~ ${Checkmark}:after {
    display: block;
    left: 6px;
    bottom: 3px;
    width: 5px;
    height: 12px;
    border: solid #000000;
    border-width: 0 2.5px 2.5px 0;
    transform: rotate(45deg);
  }
`;

export const PageSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const AddButton = styled.div`
  /* border-radius: 100px; */
  /* background-color: rgba(92, 112, 136, 1); */
  background-color: #ffba00;
  display: flex;
  padding: 0.2rem 1.5rem;
  gap: 10px;
  color: #000;
  align-items: center;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
`;
export const HeadTitle = styled.div`
  font-size: 1.8rem;
  color: #fff;
  font-weight: 600;
  text-transform: capitalize;
`;
export const CheckboxContainer = styled.div`
  display: inline-block;
  padding: 8px 10px;
`;

export const ThCheckboxContainer = styled(CheckboxContainer)`
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 0;
  padding-top: 0;
  display: flex;
`;
export const TableSection = styled.div`
  width: 100%;
  height: 100%;
  padding: 5px;
  /* overflow-x: scroll; */
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const Table = styled.table`
  width: 100%;
  background-color: #000;
  border-collapse: collapse;
  color: #fff;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "0.85rem")};
`;

export const Th = styled.th`
  background-color: rgb(39, 39, 39);
  padding: 3px 15px;
  text-align: left;
  font-weight: 600;
`;
export const ThStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 45px;
  text-transform: capitalize;
  /* gap:10px; */
`;

export const Td = styled.td`
  padding: 8px 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;

export const Tr = styled.tr``;
export const Thead = styled.thead`
  background-color: #000000;
  color: white;
`;

export const PermissionBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;
  padding: 0.5rem 1rem 2rem 1rem;
  background: #000;
  border: ${(props) =>
    props.error || props.erroros
      ? "1px solid red"
      : props.themeColor
      ? "1px solid transparent"
      : "1px solid transparent"};
  border-radius: 8px;
`;
export const ChooseFilter = styled.div`
  width: 48%;
  display: flex;
  align-items: center;
  border-radius: 8px;
  flex-direction: column;
  @media (max-width: 769px) {
    width: 100%;
  }
`;
export const InnerChooseFilter = styled.div`
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #353535;
  border-radius: 8px;
`;
export const AllSelectionRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  font-size: 0.9rem;
  margin-top: 1rem;
  font-weight: 400;
  cursor: pointer;
`;
export const ChooseHeader = styled.div`
  width: 100%;
  padding: 10px 0;
  color: #fff;
  font-size: 1.1rem;

  border-radius: 8px 8px 0 0;
  display: flex;
  font-weight: 300;
  align-items: center;
  text-transform: capitalize;
  i {
    color: white;
    font-size: 1.3rem;
    margin-left: 5px;
  }
`;
export const FilterRow = styled.div`
  width: 100%;
  display: flex;
  border: 1px solid #353535;
  padding: 8px 5px;
  align-items: center;
  border-radius: 10px;
  img {
    height: 15px;
    width: 15px;
    margin-left: 0.5rem;
  }
`;
export const FilterBox = styled.input`
  width: 93%;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 5px;
  margin-left: 5px;
  outline: none;
  background-color: transparent;
  color: #fff;

  input::placeholder {
    font-size: 0.8rem !important;
    color: ${(props) => (props.themeColor ? "#94969C" : "#8A94A6")};
  }
`;
export const FilterListBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 3px 0;
  height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #353535;
  border-radius: 0 0 8px 8px;

  /* ::-webkit-scrollbar-thumb {
    background-color: #000;
   
  } */
  &::-webkit-scrollbar-track {
    background-color: black !important;
  }

  /* Optional: Targets the scrollbar thumb (the part you drag) */
  &::-webkit-scrollbar-thumb {
    background-color: gray !important; /* You can choose a different color for the thumb */
    border-radius: 10px !important; /* Optional: Rounds the corners of the scrollbar thumb */
  }

  /* Optional: Targets the scrollbar itself (usually to set the width or height) */
  &::-webkit-scrollbar {
    width: 8px !important; /* Set the width of the vertical scrollbar */
    height: 8px !important; /* Set the height of the horizontal scrollbar */
  }
`;
export const FilterList = styled.div`
  width: 100%;
  color: #fff;
  font-size: 1rem;
  margin: 1px 0;
  font-weight: 400;
  padding: 5px 10px;
  background-color: ${(props) => props.activeColor};
  cursor: pointer;
  /* text-transform: capitalize; */
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    height: 15px;
    width: 15px;
    object-fit: contain;
  }
`;
export const ArrowBox = styled.div`
  /* width: 6%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  @media (max-width: 769px) {
    margin: 1rem 0;
    flex-direction: row;
  }
`;
export const ComArrow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    height: 20px;
    width: 20px;
  }
`;
export const NoData = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin: auto;
`;
export const PermissionBtn = styled.div`
  /* width: 150px; */
  padding: 0.6rem 2rem;

  background-color: ${({ chooseAll }) =>
    chooseAll ? "rgba(92, 112, 136, 1)" : "rgba(220, 53, 69, 1)"};
  text-transform: uppercase;
  text-align: center;
  /* border-radius: 100px; */
`;

export const FormSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 2rem;
`;
export const FormColumn = styled.div`
  width: 100%;
  background-color: ${({ bg }) => (bg ? bg : "#000")};
  /* background-color: rgb(251, 255, 255); */
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => (gap ? gap : "20px")};
  padding: 1rem;
`;
export const FormColumnHeading = styled.div`
  color: #fff;
  font-weight: 400;
  font-size: 1.3rem;
  text-transform: capitalize;
`;
export const FormRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
export const CheckboxParent = styled.div`
  height: auto;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto;
  line-height: 40px;
`;
export const UploadLabel = styled.div`
  font-size: 1rem;
  color: #fff;
`;
export const FormSubmitBtn = styled.button`
  width: ${({ width }) => (width ? width : "200px")};
  padding: 0.8rem;
  background-color: rgba(255, 186, 0, 1);
  font-weight: 700;
  font-size: 0.9rem;
  text-transform: uppercase;
  /* text-align: center; */
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  &:focus {
    border: none;
    outline: none;
  }
`;
export const ModalContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  /* max-height: 100vh; */
`;
export const HeadSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  /* border:1px solid white; */
`;
export const TextSection = styled.div`
  flex: 1;
  /* text-align: center; */
  color: #fff;
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: uppercase;
`;
export const CrossIconContainer = styled.div`
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;
export const ModalBody = styled.div`
  margin: 5px 0;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const FolderSectionBtn = styled.div`
  cursor: pointer;
  /* border-radius: 100px; */
  padding: 0.3rem 2rem;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.9rem;
  /* font-weight: 450; */
  border: ${({ active }) =>
    active ? "1px solid transparent" : "1px solid #BABABA"};
  background-color: ${({ active }) =>
    active ? "rgba(255, 186, 0, 1)" : "transparent"};
  color: ${({ active }) => (active ? "#000" : "#ADADAD")};
`;

export const FolderSectionFilterText = styled.div`
  cursor: pointer;
  font-size: 1.1rem;
  padding: 0 15px;
  text-transform: capitalize;
  color: ${({ active }) =>
    active ? "rgba(220, 53, 69, 1)" : "rgba(0, 0, 0, 1)"};
  font-weight: ${({ active }) => (active ? "700" : "400")};
`;
export const FileRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
`;
export const FileBox = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  /* align-items: center; */
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid rgba(53, 53, 53, 1)"};
  border-radius: 8px;
  padding: 0.8rem 1rem;
  overflow: hidden;
  position: relative;
  gap: 10px;
  img {
    height: 40px;
    width: 40px;
    margin: 0 !important;
    object-fit: contain;
  }
`;
export const ContentRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  /* border:1px solid #000; */
`;
export const ThumbnailRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  /* border:1px solid #000; */
`;
export const FileContentBox = styled.div`
  /* border:1px solid black; */
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 8px;
  text-transform: capitalize;
  gap: 2px;
  .head {
    font-weight: 500;
    font-size: 0.9rem;
    color: #fff;
    /* width: 90%; */
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .desc {
    font-weight: 400;
    font-size: 0.8rem;
    color: #000;
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .row-text {
    font-weight: 400;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    color: #000;
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    gap: 0.3rem;
  }
  span {
    color: #17b26a;
  }

  @media (max-width: 532px) {
    .head {
      width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const FileManagerParent = styled.div`
  width: 100%;
  min-height: 80vh;
  /* display: flex;
  flex-wrap: wrap; */
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* Creates 5 columns */
  grid-gap: 10px;
  /* padding: 2rem; */
`;
export const FolderParent = styled.div`
  /* padding: 0.25rem 1rem; */
  color:#fff;
  height: 50px;
  border-radius: 5px;
  /* width: 170px; */
  /* border: 1px solid black; */
  display: flex;
  cursor: pointer;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
  gap: 5px;
  /* justify-content: space-between; */
  align-items: center;
  background-color: transparent;
  .folder-text {
    color:#fff;
    font-size: 0.8rem;
    text-transform: capitalize;
    /* border:1px solid black; */
    width: 600px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const DeleteContainer = styled.div`
  padding: 1rem 0;
  width: 100%;
  /* display: none; */
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  border-radius: 14px 14px 0px 0px;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  position: fixed;
  bottom: 0;
  left: 0;
`;
export const DeleteComButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#24262d")};
  font-weight: 400;
  /* margin-right: 3rem; */

  img {
    height: 22px;
    width: 22px;
    object-fit: contain;
    margin-bottom: 0.6rem;
  }
`;
export const DashboardParent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`;
export const DashboardHeading = styled.div`
  font-size: 2rem;
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
  color: #fff;
  /* margin-bottom: 1.5rem; */
  .sub-word {
    font-size: 2rem;
    color: #ffba00;
  }
`;
export const DashboardCard = styled.div`
  background-color: #000;
  /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
  border-radius: 10px;
  width: 24%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .bold-number {
    font-size: 1.1rem;
    font-weight: 500;
    color: #fff;
  }
  .bold-text {
    font-size: 0.9rem;
    font-weight: 800;
    color: #fff;
    /* color: rgba(66, 81, 102, 1); */
  }
  .link {
    width: 150px;
    font-size: 0.9rem;
    color: #269ef2;
    /* border:1px solid black; */
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    cursor: pointer;
  }
`;
