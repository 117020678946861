import axios from "axios";
import { API_URL } from "../config/constant";

const instance = axios.create({
  baseURL: API_URL,
});

instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

if (localStorage.getItem("access_token_jeep_accessToken")) {
  const JWT_token = localStorage.getItem("access_token_jeep_accessToken");
  instance.defaults.headers.common["Authorization"] = `${JWT_token}`;
}

export const updateAccessToken = (accessToken) => {
  instance.defaults.headers.common["Authorization"] = `${accessToken}`;
};

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response;
  },
  (error) => {
    // Do something with response error
    if (error.response && error.response.status === 401) {
      // Handle 401 error
      //   localStorage.removeItem("rainDataToken");
      localStorage.removeItem("accessToken");
      localStorage.clear();
      window.location.href = "/"; // Redirect to login or home
    }

    // Return any error not to be handled
    return Promise.reject(error);
  }
);

export default instance;
