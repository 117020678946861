import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";

export const SidebarBox = styled.div`
  width: 100%;
  height: 100%;
  background-color: #000;
  color: white;
  min-height: 100vh;
  overflow: hidden;
  padding: 1rem 0 1rem 0rem;
`;
export const LogoBox = styled.div`
  /* border: 1px solid white; */
  padding-left: 2rem;
  height: 80px;
`;
export const SidebarBody = styled.div`
  /* border: 1px solid white; */
  /* min-height: 50vh; */
  display: flex;
  flex-direction: column;
  padding: 2rem 0 2rem 2rem;
  gap: 15px;
`;
export const SidebarBodyContainer = styled.div`
  /* border:1px solid white; */
  scroll-behavior: smooth;
  height: calc(100vh - 80px);

  &::-webkit-scrollbar {
    display: none;
  }
  /* &::-webkit-scrollbar {
    width: 7px;
  } */

  /* &::-webkit-scrollbar-track {
    background: #000;
    border-radius: 30px;
  }

  &::-webkit-scrollbar-thumb {
    background: #8d8d8d;
    border-radius: 30px;
  } */
`;
export const SidebarItem = styled(Link)`
  width: 100%;
  height: 40px;
  /* background-color: ${({ isActive }) =>
    isActive ? "rgb(255, 186, 0)" : "transparent"}; */
  border-top-left-radius: 32px;
  border-bottom-left-radius: 32px;
  display: flex;
  gap: 15px;
  align-items: center;
  padding-left: 15px;
  user-select: none;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  border-right: ${({ isActive }) =>
    isActive ? "8px solid rgb(255, 186, 0)" : "8px solid transparent"};
  /* color:${({ isActive }) =>
    isActive ? "#000" : "rgba(147, 147, 147, 1)"}; */
  color: ${({ isActive }) => (isActive ? "rgb(255, 186, 0)" : "#fff")};
  /* &.active {
    background-color: rgb(255, 186, 0);
    color: black;
  } */
  /* &:hover {
    background-color: rgb(255, 186, 0);
    color: white;
  } */
  .item-label {
    font-weight: 600;
    font-size: 14px;
  }
`;
export const SidebarItemLogout = styled.div`
  width: 100%;
  height: 50px;
  text-transform: uppercase;
  background-color: ${({ isActive }) =>
    isActive ? "rgb(255, 186, 0)" : "transparent"};
  border-top-left-radius: 32px;
  border-bottom-left-radius: 32px;
  display: flex;
  gap: 15px;
  align-items: center;
  padding-left: 15px;
  user-select: none;
  cursor: pointer;
  text-decoration: none;
  color: rgba(201, 59, 43, 1);
  /* &.active {
    background-color: rgb(255, 186, 0);
    color: black;
  } */
  /* &:hover {
    background-color: rgb(255, 186, 0);
    color: white;
  } */
  .item-label {
    font-weight: 700;
    font-size: 15px;
  }
`;
export const SidebarLogoImage = styled.div`
  /* border: 1px solid white; */
  padding: 1rem;
  margin-bottom: 3rem;
`;
export const SubItem = styled.div`
  padding: 10px 0 5px 50px;
  font-size: 14px;
  .subitem-link {
    color: white;
    text-decoration: none;
    font-size: 14px;
    text-transform: uppercase;
    &:hover {
      text-decoration: none;
      color: yellow;
    }
    &.active {
      color: yellow; /* Active link color */
    }
  }
`;

export const DropdownContainer = styled.div`
  overflow: hidden;
  height: 0;
  transition: height 0.3s ease-in-out;
`;
