import { USER_DATA,SET_CURRENT_PARENT } from "./actionTypes";

const setUserData = (data) => {
  return {
    type: USER_DATA,
    data,
  };
};
const setCurrParent = (data) => {
  return {
    type: SET_CURRENT_PARENT,
    data,
  };
};

export { setUserData,setCurrParent };
