import { io } from "socket.io-client";

// Replace with your server URL
const SOCKET_URL = "http://43.204.78.67:3000";

// Initialize the socket connection
const socket = io(SOCKET_URL, {
  transports: ["websocket"],
});

export default socket;
