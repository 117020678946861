import { useEffect, useState } from "react";
import {
  Flexcenter,
  Leftbox,
  Navbox,
  Rightbox,
} from "../../components/Navbar/NavbarStyle";
// import Profileoption from "../../components/dropdowns/Profileoption";
import { MdOutlineMenu } from "react-icons/md";
import * as SS from "../../style/Sidestyle";
import notify from "../../assets/notification.png";
import ProfileImg from "../../assets/profile.png";
import { MdOutlineLightMode } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
// import { setIsDark, setIsSidebar } from "./action";
import blank_profile from "../../assets/blank-profile-picture.png";
import socket from "../../utils/socket";

const Navbar = () => {
  const UserData = useSelector((state) => state.userData);

  useEffect(() => {
    // Listen for incoming messages
    socket.on("connect", () => {
      console.log("Connected to server");
      socket.emit("joinRoom", "dealer123"); // Replace 'dealer123' with an actual room ID
    });

    socket.on("receiveNotification", (data) => {
      console.log("test");
      console.log("Notification received:", data);
    });

    socket.on("disconnect", () => {
      console.log("Disconnected from server");
    });

    // Clean up the socket connection when the component unmounts
    return () => {
      socket.off("message");
    };
  }, []);

  return (
    <>
      <Navbox>
        <Rightbox>
          <ul>
            <li>
              <SS.Roundbox>
                <img src={notify} alt="nav_icon" />
              </SS.Roundbox>
            </li>
            <li>
              <Flexcenter>
                <img
                  src={
                    UserData?.profile_image
                      ? UserData.profile_image
                      : blank_profile
                  }
                  alt="profile_icon"
                />
              </Flexcenter>
            </li>
            <li>
              <SS.ProfileCont>
                <SS.ProfileName>{`${UserData?.first_name || ""} ${
                  UserData?.last_name || ""
                }`}</SS.ProfileName>
                <SS.ProfileTitle>
                  {UserData.isSuperAdmin ? "Super Admin" : "Admin"}
                </SS.ProfileTitle>
              </SS.ProfileCont>
            </li>
          </ul>
        </Rightbox>
      </Navbox>

      {/* Dropdown for notification icon */}
    </>
  );
};

export default Navbar;
