import { lazy } from "react";

const Login = lazy(() => import("../pages/Login.js"));
const Dashboard = lazy(() => import("../pages/Dashboard.js"));
const AddRole = lazy(() => import("../pages/AddRole/AddRole.js"));
const DealerManagement = lazy(() =>
  import("../pages/DealerManagement/DealerManagement.js")
);
const AddRoleForm = lazy(() => import("../pages/AddRole/CreateRoleForm.js"));
const EditRoleForm = lazy(() => import("../pages/AddRole/EditRoleFrom.js"));
const AddUser = lazy(() => import("../pages/AddUser/AddUser.js"));
const AddUserForm = lazy(() => import("../pages/AddUser/CreateUserForm.js"));
const EditUserForm = lazy(() => import("../pages/AddUser/EditUserForm.js"));
const JeepBrand = lazy(() =>
  import("../pages/BrandManagement/JeepBrandManagement.js")
);
const CitroenBrand = lazy(() =>
  import("../pages/BrandManagement/CitroenBrandManagement.js")
);
const JeepLinks = lazy(() => import("../pages/Links/JeepLinksList.js"));
const CitroenLinks = lazy(() => import("../pages/Links/CitroenLinksList.js"));
const AddAgency = lazy(() => import("../pages/AgencyManagement/AddAgency.js"));

const AddAgencyUser = lazy(() =>
  import("../pages/AgencyManagement/AddAgencyUser.js")
);
const FileUploads = lazy(() =>
  import("../pages/AgencyManagement/FileUploaded/FileUploaded.js")
);
const AddAgencyForm = lazy(() =>
  import("../pages/AgencyManagement/AddAgencyForm.js")
);
const EditAgencyForm = lazy(() =>
  import("../pages/AgencyManagement/EditAgencyForm.js")
);
const AddAgencyUserForm = lazy(() =>
  import("../pages/AgencyManagement/AddAgencyUserForm.js")
);
const EditAgencyUserForm = lazy(() =>
  import("../pages/AgencyManagement/EditAgencyUserForm.js")
);
const AddJeepDealer = lazy(() =>
  import("../pages/DealerManagement/AddDealer.js")
);
const AddCitroenDealer = lazy(() =>
  import("../pages/DealerManagement/Citroen/AddDealerCitroen.js")
);
const Links = lazy(() => import("../pages/Links/Links.js"));
const Citroen = lazy(() => import("../pages/Links/Citroen.js"));
const JeepFileManager = lazy(() =>
  import("../pages/FileManagement/Jeep/index.js")
);
const CitroenFileManager = lazy(() =>
  import("../pages/FileManagement/Citroen/index.js")
);
const JeepFileAdd = lazy(() =>
  import("../pages/FileManagement/Jeep/CreateNewForm.js")
);
const CitroenFileAdd = lazy(() =>
  import("../pages/FileManagement/Citroen/CreateNewForm.js")
);
const DealerCitroen = lazy(() =>
  import("../pages/DealerManagement/Citroen/DealerCitroen.js")
);

const AddDealerCitroen = lazy(() =>
  import("../pages/DealerManagement/Citroen/AddDealerCitroen.js")
);
const ProductCategory = lazy(() =>
  import("../pages/ProductCategory/ProductCategoryList.js")
);
const UserProfile = lazy(() =>
  import("../pages/ProfileSetting/ProfileSetting.js")
);
const EditJeepDealer = lazy(() =>
  import("../pages/DealerManagement/EditJeepDealer.js")
);
const EditCitroenDealer = lazy(() =>
  import("../pages/DealerManagement/Citroen/EditDealerCitroen.js")
);
const EditFileform = lazy(() =>
  import("../pages/FileManagement/Jeep/EditFileForm.js")
);
const EditCetroinFileform = lazy(() =>
  import("../pages/FileManagement/Citroen/EditFileForm.js")
);

const JeepTrash = lazy(() => import("../pages/TrashManagement/JeepTrash.js"));
const CitroenTrash = lazy(() =>
  import("../pages/TrashManagement/CitroenTrash.js")
);
const ProductCategoryTest = lazy(() =>
  import("../pages/ProductCategory/ProductCategoryListCopy.js")
);
const CreateFormTest = lazy(() =>
  import("../pages/FileManagement/Jeep/CreateNewFormCopy.js")
);
const JeepFiles = lazy(() =>
  import("../pages/NewFileManagement/JeepFiles.jsx")
);
const JeepCatFiles = lazy(() =>
  import("../pages/TrashManagement/JeepTrash/JeepCat.jsx")
);
const JeepTrashFiles = lazy(() =>
  import("../pages/TrashManagement/JeepTrash/index.js")
);
const JeepBrandFiles = lazy(() =>
  import("../pages/TrashManagement/JeepTrash/JeepBrands.jsx")
);
const CitroenFiles = lazy(() =>
  import("../pages/NewFileManagement/CitroenFiles.jsx")
);
const CitroenCatFiles = lazy(() =>
  import("../pages/TrashManagement/CitroenTrash/CitroenCat.js")
);
const CitroenTrashFiles = lazy(() =>
  import("../pages/TrashManagement/CitroenTrash/index.js")
);
const CitroenBrandFiles = lazy(() =>
  import("../pages/TrashManagement/CitroenTrash/CitroenBrands.jsx")
);
const UploadCitroenFiles = lazy(() =>
  import("../pages/FileManagement/Citroen/CreateNewFormCopy.js")
);
const JeepDealerLogsListing = lazy(() =>
  import("../pages/DealerLogs/JeepDealerListiing.jsx")
);
const JeepDealerDetailLogs = lazy(() =>
  import("../pages/DealerLogs/JeepLogs.jsx")
);
const CitroenDealerLogsListing = lazy(() =>
  import("../pages/DealerLogs/CitroenDealerListing.jsx")
);
const CitroenDealerDetailLogs = lazy(() =>
  import("../pages/DealerLogs/CitroenLogs.jsx")
);

export const publicRoutes = [{ path: "/adminPanel", component: Login }];
export const privateRoutes = [
  { path: "/adminPanel/home", component: Dashboard },
  { path: "/adminPanel/add-role", component: AddRole },
  { path: "/adminPanel/dealer_management", component: DealerManagement },
  { path: "/adminPanel/create-role", component: AddRoleForm },
  { path: "/adminPanel/edit-role/:id", component: EditRoleForm },
  { path: "/adminPanel/add-user", component: AddUser },
  { path: "/adminPanel/edit-user/:id", component: EditUserForm },
  { path: "/adminPanel/edit-jeep-dealer/:id", component: EditJeepDealer },
  { path: "/adminPanel/edit-citroen-dealer/:id", component: EditCitroenDealer },
  { path: "/adminPanel/create-user", component: AddUserForm },
  { path: "/adminPanel/jeep-brand", component: JeepBrand },
  { path: "/adminPanel/citroen-brand", component: CitroenBrand },
  { path: "/adminPanel/jeep-links", component: JeepLinks },
  { path: "/adminPanel/jeep-dealer-logs", component: JeepDealerLogsListing },
  { path: "/adminPanel/jeep-detail-logs/:id", component: JeepDealerDetailLogs },
  {
    path: "/adminPanel/citroen-dealer-logs",
    component: CitroenDealerLogsListing,
  },
  {
    path: "/adminPanel/citroen-detail-logs/:id",
    component: CitroenDealerDetailLogs,
  },
  { path: "/adminPanel/citroen-links", component: CitroenLinks },
  { path: "/adminPanel/add-agency", component: AddAgency },
  { path: "/adminPanel/edit-agency/:ID", component: EditAgencyForm },
  { path: "/adminPanel/add-agency-user", component: AddAgencyUser },
  { path: "/adminPanel/edit-agency-user/:id", component: EditAgencyUserForm },
  { path: "/adminPanel/file-uploads", component: FileUploads },
  { path: "/adminPanel/create-agency", component: AddAgencyForm },
  { path: "/adminPanel/create-agency-user", component: AddAgencyUserForm },
  { path: "/adminPanel/add-jeep-dealer", component: AddJeepDealer },
  { path: "/adminPanel/add-citroen-dealer", component: AddCitroenDealer },
  { path: "/adminPanel/links", component: Links },
  { path: "/adminPanel/citroen", component: Citroen },
  { path: "/adminPanel/jeep-file-manager", component: JeepFileManager },
  { path: "/adminPanel/citroen-file-manager", component: CitroenFileManager },
  { path: "/adminPanel/create-jeep-file", component: JeepFileAdd },
  { path: "/adminPanel/create-citroen-file", component: CitroenFileAdd },
  { path: "/adminPanel/dealer-citroen", component: DealerCitroen },
  { path: "/adminPanel/add-dealer-citroen", component: AddDealerCitroen },
  { path: "/adminPanel/product-category", component: ProductCategory },
  { path: "/adminPanel/user-profile", component: UserProfile },
  { path: "/adminPanel/edit-file-form/:id", component: EditFileform },
  {
    path: "/adminPanel/edit-cetroin-file-form/:id",
    component: EditCetroinFileform,
  },
  { path: "/adminPanel/jeep-trash", component: JeepTrash },
  { path: "/adminPanel/citroen-trash", component: CitroenTrash },
  {
    path: "/adminPanel/product-category-test/:id/:type",
    component: ProductCategoryTest,
  },
  {
    path: "/adminPanel/create-form-test/:prodId/:catId",
    component: CreateFormTest,
  },
  { path: "/adminPanel/jeep-files/:prodId/:catId", component: JeepFiles },
  {
    path: "/adminPanel/jeep-trash-files/:prodId/:catId",
    component: JeepTrashFiles,
  },
  { path: "/adminPanel/jeep-brand-files", component: JeepBrandFiles },
  { path: "/adminPanel/jeep-category-files/:id", component: JeepCatFiles },
  { path: "/adminPanel/citroen-files/:prodId/:catId", component: CitroenFiles },
  {
    path: "/adminPanel/citroen-trash-files/:prodId/:catId",
    component: CitroenTrashFiles,
  },
  { path: "/adminPanel/citroen-brand-files", component: CitroenBrandFiles },
  {
    path: "/adminPanel/citroen-category-files/:id",
    component: CitroenCatFiles,
  },
  {
    path: "/adminPanel/upload-citroen-files/:prodId/:catId",
    component: UploadCitroenFiles,
  },
];
