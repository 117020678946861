import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { privateRoutes, publicRoutes } from "./routes";
import { nanoid } from "nanoid";
import { Suspense } from "react";
import DetailLoader from "./components/DetailLoader";
import * as SE from "./style/LayoutStyle";
import Sidebar from "./components/Sidebar/Sidebar";
import Navbar from "./components/Navbar/Navbar";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";

function App() {
  const userData = useSelector((state) => state.userData);
  const PublicRoute = () => {
    const navigate = useNavigate();
    let location = useLocation();
    return (
      <Routes>
        {publicRoutes.map((val, ind) => (
          <Route
            key={nanoid()}
            exact
            path={val.path}
            element={
              <Suspense fallback={<DetailLoader />}>
                <SE.ParentContainer>
                  <SE.InnerContainer>
                    <SE.SectionBox>
                      <val.component navigate={navigate} location={location} />
                    </SE.SectionBox>
                  </SE.InnerContainer>
                </SE.ParentContainer>
              </Suspense>
            }
          />
        ))}
        <Route path="/*" element={<Navigate to="/adminPanel" />} />
      </Routes>
    );
  };

  const PrivateRoute = () => {
    const navigate = useNavigate();
    return (
      <Routes>
        {privateRoutes.map((val, ind) => (
          <Route
            key={nanoid()}
            exact
            path={val.path}
            element={
              <SE.PrivateContainer>
                <SE.PrivateInnerContainer>
                  <SE.PrivateFirstContainer>
                    <Sidebar />
                  </SE.PrivateFirstContainer>
                  <SE.PrivateSecondContainer>
                    <Navbar />
                    <SE.InnerPrivateContainer>
                      <Suspense fallback={<DetailLoader />}>
                        <val.component navigate={navigate} />
                      </Suspense>
                    </SE.InnerPrivateContainer>
                  </SE.PrivateSecondContainer>
                </SE.PrivateInnerContainer>
              </SE.PrivateContainer>
            }
          />
        ))}
        <Route path="/" element={<Navigate to="/adminPanel/home" />} />
      </Routes>
    );
  };

  return (
    <>
      {userData ? <PrivateRoute /> : <PublicRoute />}

      <ToastContainer />
    </>
  );
}

export default App;
