import { Link, NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

export const Sidebox = styled.div`
  width: 70px;
  background: rgb(30, 30, 30);
  display: flex;
  flex-direction: column;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: ${({ issidebar }) => (issidebar ? "none" : "block")};

  @media (max-width: 970px) {
    display: none !important;
  }
`;

export const Contentbox = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;

export const Iconbox = styled(Link)`
  border: 5px solid transparent;
  text-decoration: none;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #0f1015;
    cursor: pointer;
    border-left: 5px solid #0090e7;
  }
`;

export const Roundbox = styled.div`
  background: ${({ theme }) => theme.navIconbg};
  border-radius: 2rem;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img{
    width: 45px;
    height: 45px;
  }

  /* .icons {
    color: rgb(64, 64, 64);
    font-size: 1.5rem;
  } */
`;

export const Fullsidebox = styled.div`
  width: 350px;
  background: rgb(21, 21, 21);
  display: ${({ issidebar }) => (issidebar ? "flex" : "none")};
  flex-direction: column;
  transition: width 0.8s ease;
  border-right: 3px solid rgb(30, 30, 30);
  height: 100vh;
  overflow: hidden;

  @media (max-width: 970px) {
    display: none !important;
  }
`;
export const FullsideboxOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 350px;
  height: 100vh;
  background: rgb(21, 21, 21);
  border-right: 3px solid rgb(30, 30, 30);
  z-index: 10000;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transform: ${({ issidebar }) =>
    issidebar ? "translateX(0%)" : "translateX(-100%)"};
  transition: transform 0.3s ease-out;
  @media (min-width: 970px) {
    display: none !important;
  }
`;

export const ScrollSidecontainer = styled.div`
  height: calc(100vh-60px);
  overflow-y: auto;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: #3b3b3b;
    border-radius: 30px;
  }

  &::-webkit-scrollbar-thumb {
    background: #8d8d8d;
    border-radius: 30px;
  }
`;

export const Fullcontentbox = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  padding: 0 2rem;
  align-items: center;
  justify-content: flex-start;
  color: #656e93;
  background: ${(props) => (props.bg ? props.bg : "rgb(21,21,21)")};
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
export const Fullcontentboxhover = styled(Link)`
  color: #656e93;
  width: 100%;
  height: 50px;
  display: flex;
  padding: 0 1.2rem;
  align-items: center;
  justify-content: flex-start;
  border: 5px solid transparent;
  text-decoration: none;
  &:hover {
    background: #0f1015;
    cursor: pointer;
    border-left: 5px solid #0090e7;
    color: white;
  }
`;

export const Value = styled.div`
  font-size: 0.9rem;

  font-weight: 300;
  padding: 0 10px;
`;

export const Fulliconbox = styled.div`
  border: 1px solid white;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Cross = styled.div`
  display: none;
  cursor: pointer;
  width: 35px;
  @media (max-width: 970px) {
    display: block;
  }
  &:hover {
    transform: scale(1.2);
  }
  &:active {
    transform: scale(1.2);
  }
`;

export const NavBox = styled.div`
  margin-top: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: rgba(137, 137, 137, 1);
  gap: 0px;
  /* padding:0 1.2rem; */
`;
export const NavBoxHead = styled.div`
  padding: 0 0 10px calc(1.5rem + 5px);
  text-transform: capitalize;
  font-size: 12px;
  color: #c2c1c1;
`;
export const NavBoxElement = styled.div`
  color: #898989;
  padding: 3px 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border: 5px solid transparent;
  text-decoration: none;
  font-size: 0.9rem;

  &:hover {
    background: #0f1015;
    cursor: pointer;
    border-left: 5px solid #0090e7;
    color: white;
  }
`;
export const LeftBox = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
export const LeftBoxLink = styled(NavLink)`
  display: flex;
  gap: 10px;
  align-items: center;
  text-decoration: none;
  color: inherit;
  &.active {
    color: white;
  }
`;
export const RightBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const DropdownBox = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  margin: 0 1.5rem;
  overflow: hidden;
  transition: max-height 0.35s ease, opacity 0.35s ease;
  max-height: 0;
  opacity: 0;
  visibility: hidden;

  ${(props) =>
    props.open &&
    `
      max-height: ${props.maxHeight}px;
      opacity: 1;
      visibility: visible;
      padding: 10px 38px;
    `}
`;
export const DropdownItem = styled(NavLink).attrs({ navLink: true })`
  text-decoration: none;
  color: inherit;
  font-size: 0.9rem;
  padding: 3px 0;
  &:hover {
    cursor: pointer;
    color: white;
  }
  &.active {
    color: white;
  }
`;
// export const NavBoxElement = styled(Link)`
//   color: #898989;
//   padding: 3px 1.5rem;
//   display: flex;
//   align-items: center;
//   gap: 10px;
//   cursor: pointer;
//   border: 5px solid transparent;
//   text-decoration: none;
//   font-size: 1.1rem;

//   &:hover {
//     background: #0f1015;
//     cursor: pointer;
//     border-left: 5px solid #0090e7;
//     color: white;
//   }
// `;
export const NavBoxIcon = styled.div`
  width: ${({ size }) => (size ? size : "25px")};
  height: ${({ size }) => (size ? size : "25px")};
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
    transform: ${({ isActive }) =>
      isActive ? "rotate(90deg)" : "rotate(0deg)"};

  /* Apply transform to the img tag based on isActive prop */
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
    
`;

export const ProfileCont = styled.div`
  width: 100%;
`;

export const ProfileName = styled.div`
  width: 100%;
`;

export const ProfileTitle = styled.div`
  width: 100%;
`;